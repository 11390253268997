<template>
  <div class="StaffMediaOutlets">
    <banded-section
      title="Media Outlets"
      :collapsable="false"
    >
      <template slot="tooltip">
        <div class="m-b-m">
          This section is visible to the Hypefactors team only.
        </div>

        <div class="m-b-m">
          Are users missing media outlets? Here you can add outlets to our database.
        </div>

        <div class="m-b-m">
          Please be sure about the impression number (for print media) before adding.
        </div>
      </template>
      <router-view />
    </banded-section>
  </div>
</template>

<script>
export default {}
</script>
